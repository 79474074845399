// If you don't want to use TypeScript you can delete this file!
import React from 'react'

import BlockContent from '@sanity/block-content-to-react'

import { Image } from 'src/components/image'
import { SEO } from 'src/components/seo'
import { Serializer } from 'src/utils/serializer'
import { RenderModules } from 'src/utils/renderModules'
import { LocationCard } from 'src/components/locations/locationCard'

import {
  SmallArrow,
  TeamShapeOne,
  TeamShapeTwo,
  TeamShapeThree,
  TeamShapeFour,
  TeamShapeFive,
} from 'src/components/svgs'
import Content from 'src/components/content'

const ShapeArray = (shape: number) => {
  const className = `abs x z1 y top team__card-shape left`
  switch (shape) {
    case 1:
      return <TeamShapeOne className={className} />
    case 2:
      return <TeamShapeThree className={className} />
    case 3:
      return <TeamShapeFour className={className} />
    case 4:
      return <TeamShapeFive className={className} />
    default:
      return <TeamShapeTwo className={className} />
  }
}

export interface TeamProps {
  pageContext: {
    main: {
      modules: []
      location: any
      facts?: any[]
      title: string
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Team = ({ path, pageContext }: TeamProps) => {
  const {
    main: {
      modules,
      title,
      location,
      locations,
      text,
      facts,
      image,
      position,
      slug,
    },
    meta,
    env,
  } = pageContext
  const shape = Math.floor(Math.random() * 3)
  const url = path
  return (
    <>
      <SEO metaInfo={meta} pagePath={path} env={env} />
      <div className="bcraspberry">
        <div className="container--l x outer cb al mxa bold back__link p1">
          <a
            href="#back"
            className="f jcs aic"
            onClick={() => window.history.back()}
          >
            <SmallArrow className="block rotate" /> Back
          </a>
        </div>
      </div>
      <div className="container--l outer p1 mxa">
        <div className="f fw jcb ais  ">
          <div className="x col-m-5 ccream rel">
            {ShapeArray(shape)}
            {image && (
              <Image className="x" imageId={image.asset._id} alt={title} />
            )}
          </div>
          <div className="x rel z1 col-m-5">
            <div className="mt1 pt1">
              <h1 className="s30 m0 p0">{title}</h1>
              <span className="p">{position}</span>
            </div>
            <div>
              {text && <BlockContent blocks={text} serializers={Serializer} />}
            </div>
          </div>
        </div>
      </div>
      {facts?.length > -1 && (
        <div className="py1 pym container--l outer cb mxa f ais fw">
          {facts?.map((fact, i) => (
            <div key={i} className="col-m-5 x team__fact f jcs ais">
              <Image
                className="x mr1 col-m-3"
                imageId={fact.image.asset._id}
                alt={title}
              />
              <div className="x col-m-6 ml1 team__fact-text mt20">
                <h5 className="caps mono py0 m0">{fact.title}</h5>
                {fact.text && (
                  <BlockContent blocks={fact.text} serializers={Serializer} />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {locations && locations.length > 0 && (
        <div className="container--l outer py1 pbm p1 mxa">
          <h3>Find me at</h3>
          <div className="teamLocations">
            {locations.map(location => (
              <LocationCard {...location} />
            ))}
          </div>
        </div>
      )}
      <Content modules={modules}></Content>
    </>
  )
}

export default Team
